import React from "react";
import { useNavigate } from "react-router-dom";
import { BiPowerOff } from "react-icons/bi";
import styled from "styled-components";
import axios from "axios";
import { logoutRoute } from "../utils/APIRoutes";
import { Tooltip } from "@mui/material";
export default function Logout() {
  const navigate = useNavigate();
  const handleClick = async () => {
    const id = await JSON.parse(
      localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
    ).user_id;
   
    console.log("response.data")
    const data = await axios.post(logoutRoute,{
      user_id:id
    });
    console.log(data)

    if (data.data.error === false) {
      localStorage.clear();
      navigate("/login");
    }
  };
  return (
    <Tooltip title="Logout">
    <Button onClick={handleClick}>
      <BiPowerOff />
    </Button>
     </Tooltip>
  );
}

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: white;
  cursor:pointer;
  border: none;
  cursor: pointer;
  svg {
    font-size: 1.3rem;
    color: #f34d4d;
  }
`;
