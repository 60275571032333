import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../assets/logoApp.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginRoute, registerRoute, updateUsernameRoute, verifyEmailRoute } from "../utils/APIRoutes";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
export default function Login() {
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState(false)
  const [loadingStateUserName, setLoadingStateUserName] = useState(false)
  const [disableUserButton, setdisableUserButton] = useState(true)
  const [values, setValues] = useState({
    email: "",
  });
  const [userName, setUserName] = useState('')
  const toastOptions = {
    position: "bottom-right",
    autoClose: 2000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };
  useEffect(() => {
    if (localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)) {
      navigate("/");
    }
  }, []);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  // valid email 
  function isValidEmail(email) {
    // Regular expression for a valid email format
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  }
  const validateForm = () => {
    const { email } = values;
    if (email === "") {
      toast.error("Email is required.", toastOptions);
      return false;
    } else if (!isValidEmail(email)) {
      toast.error("Please enter a valid email address.", toastOptions);
      return false;
    }
    return true;
  };
  const [open, setOpen] = useState(false)
  const [StateResult, setStateResult] = useState()
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingState(true)
    if (validateForm()) {
      const { email } = values;
      const { data } = await axios.post(verifyEmailRoute, {
        email,
      });
      console.log(data)
      if (data.error === true) {
        toast.error(data.message, toastOptions);
        setLoadingState(false)

      } else {
        console.log("Data ")
        console.log(data)
        setStateResult(data.data)
        setLoadingState(false)

 navigate(`/verificationOTP/${data.data.uniq_id}`)
        //   if (data.data.user_name === "" || data.data.user_name === null || data.data.user_name === undefined) {
        //     setOpen(true)

        //   } else {
        //     // localStorage.setItem(
        //     //   process.env.REACT_APP_LOCALHOST_KEY,
        //     //   JSON.stringify(data.data)
        //     // );
        //     // navigate("/");
        // setLoadingState(false)

        //     toast.success("Verification OTP has been send to your email ", toastOptions);

        //   }

      }

    } else {
      setLoadingState(false)

    }
  };
  const saveUserName = async () => {
    console.log("StateResult")

    console.log(StateResult)
    if (userName === "" || userName === null || userName === undefined) {
      setdisableUserButton(true)
    } else {
      setdisableUserButton(false)

      setLoadingStateUserName(true)
      // API CALL TO UPDATE AND NAVIGATE 
      const { data } = await axios.post(updateUsernameRoute, {
        user_name: userName,
        user_id: StateResult.user_id
      });
      console.log(data)
      if (data.error === true) {
        toast.error(data.message, toastOptions);
        setLoadingStateUserName(false)

      } else {
        console.log("data")

        // console.log(data)
        // login again 
        const { email } = values;
        const { data1 } = await axios.post(loginRoute, {
          email,
        });
        console.log(data1)
        if (data1.error === true) {
          toast.error(data1.message, toastOptions);
          setLoadingState(false)

        } else {
          setLoadingState(false)

          toast.success("Verification OTP has been send to your email ", toastOptions);

          // localStorage.setItem(
          //   process.env.REACT_APP_LOCALHOST_KEY,
          //   JSON.stringify(data1.data)
          // );
          // navigate("/");
        }

      }
    }


  }

  return (
    <>
      <FormContainer>
        <form action="" onSubmit={(event) => handleSubmit(event)}>
          <div className="brand">
            <img src={Logo} alt="logo" />
          </div>
          <TextField
            name="email"
            onChange={(e) => handleChange(e)}
            sx={{ width: '100%' }} id="outlined-basic"
            label="Email"
            variant="outlined" />

          <LoadingButton className="buttonClass" loading={loadingState}
            type="submit">
            Login
          </LoadingButton>
        </form>
        {/* Dialog for userName  */}
        <Dialog
          maxWidth="md"
          open={open}
        // onClose={handleClose}
        >
          <DialogTitle>Set your name  </DialogTitle>
          <DialogContent>
            <TextField
              name="user_name"
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value)
                if (e.target.value.length === 0) {
                  setdisableUserButton(true)
                } else {
                  setdisableUserButton(false)

                }

              }}
              sx={{ width: '100%', marginTop: '10px' }} id="outlined-basic"
              label="Name"
              variant="outlined" />
            <LoadingButton
              style={
                {
                  padding: "0.5rem 2rem",
                  border: "none",
                  fontWeight: "bold",
                  cursor: "pointer",
                  borderRadius: "0.4rem",
                  fontSize: "1rem",
                  textTransform: "uppercase",
                  marginTop: 20, width: '100%', backgroundColor: "#5e84fc", color: 'white'
                }}
              loading={loadingStateUserName} disabled={disableUserButton}
              onClick={() => saveUserName()}>
              Save
            </LoadingButton>
          </DialogContent>

        </Dialog>
      </FormContainer>
      <ToastContainer />
    </>
  );
}

const FormContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #f1efef;
  .brand {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    img {
      height: 4rem;
    }
    h1 {
      color: black;
      text-transform: uppercase;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #ffffff;
    border-radius: 2rem;
    padding: 5rem;
  }
  input {
    background-color: transparent;
    padding: 1rem;
    border-radius: 0.4rem;
    color: black;
    width: 100%;
    font-size: 1rem;
    &:focus {
      outline: none;
    }
  }
  .buttonClass {
    background-color: #5e84fc;
    color: white;
    padding: 0.5rem 2rem;
    border: none;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0.4rem;
    font-size: 1rem;
    text-transform: uppercase;
    &:hover {
      background-color: #99cde3;
    }
  }
  span {
    color: black;
    text-transform: uppercase;
    a {
      color: #53bdeb;
      text-decoration: none;
      font-weight: bold; 
      &:hover{
        color: #99cde3;
    }
    }
   
  }
 
`;
