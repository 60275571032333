import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import styled from "styled-components";
import { allUsersRoute, host, readMessagesRoute } from "../utils/APIRoutes";
import ChatContainer from "../components/ChatContainer";
import Contacts from "../components/Contacts";
import Welcome from "../components/Welcome";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Chat() {
  const navigate = useNavigate();
  const socket = useRef();
  const [contacts, setContacts] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [open ,setOpen] =useState(true)
  const handleChatChange = async (chat) => {
    setCurrentChat(chat);
    console.log("chat")

    console.log(chat)
    // set mark as Read Messages 
    const data = await axios.post(`${readMessagesRoute}`, {
      from_user: chat.user_id,
      to_user: currentUser.user_id
    });
    console.log("Read Messages")
    console.log(data)
    const data1 = await axios.post(`${allUsersRoute}`, {
      user_id: currentUser.user_id,
      type:currentUser.type
    });
    console.log("All Contacts")

    console.log(data1.data)
    setContacts(data1.data.result);

  };
  useEffect(async () => {
    if (!localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)) {
      navigate("/login");
    } else {
      setCurrentUser(
        await JSON.parse(
          localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
        )
      );
    }
  }, []);
  useEffect(() => {
    if (currentUser) {
      socket.current = io(host);
      socket.current.emit("add-user", currentUser.user_id);
    }
  }, [currentUser]);

  useEffect(async () => {
    if (currentUser) {
      console.log("currentUser")

      console.log(currentUser)
      const data = await axios.post(`${allUsersRoute}`, {
        user_id: currentUser.user_id,
        type:currentUser.type
      });
      console.log("All Contacts")
      setOpen(false)
      console.log(data.data.result)
      setContacts(data.data.result);
      // if (currentUser.isAvatarImageSet) {
      //   const data = await axios.get(`${allUsersRoute}/${currentUser._id}`);
      //   setContacts(data.data);
      // } else {
      //   navigate("/setAvatar");
      // }
    }
    // socket 
    console.log('socket.current7')

    console.log(socket.current)
    if (socket.current) {
      const messageListener = async (msg) => {
        console.log(msg)

        console.log("msguuuuuuuu")
        const data = await axios.post(`${allUsersRoute}`, {
          user_id: msg.user_id,
          type:msg.type
        });
        console.log("All Contacts")
        setOpen(false)
        console.log(data.data)
        setContacts(data.data.result);


      };
      socket.current.on("unread-messages-updated-admin", messageListener);

      return () => {
        socket.current.off("unread-messages-updated-admin", messageListener);
      };
    }
  }, [currentUser]);

  return (
    <>
      <Container>
        {open?<>
          <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        </>:
        <>  <div className="container">
          <Contacts contacts={contacts} changeChat={handleChatChange} />
          {currentChat === undefined ? (
            <Welcome />
          ) : (
            <ChatContainer currentChat={currentChat} socket={socket} />
          )}
        </div>
        </>}
      
      </Container>
    </>
  );
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
 
  background-color: #cde9f5;
  .container {
    
    height: 95vh;
    width: 95vw;
    border-radius:20px;
    background-color: #f0f0f0;
    display: grid;
    grid-template-columns: 25% 75%;
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      grid-template-columns: 35% 65%;
    }
  }
`;
