import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../assets/logoApp.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dummyImage from "../assets/dummyimage.png"
import { registerRoute, uploadImageRoute } from "../utils/APIRoutes";
import { LoadingButton } from "@mui/lab";
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function Register() {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [userChatLogo, setuserChatLogo] = useState("")
  const [image, setImage] = useState("")
  const [loadingState,setLoadingState]=useState(false)

  // Pick Image 
  const handleClick = () => {
    // open file input box on click of other element
    inputRef.current.click();
  };
  const onFileChange1 = (e) => {
    setImage(e)
    setuserChatLogo(URL.createObjectURL(e))
  }
  const toastOptions = {
    position: "bottom-right",
    autoClose: 2000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };
  const [values, setValues] = useState({
    user_name: "",
    email: "",
    password: "",

  });
  // password 
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    if (localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)) {
      navigate("/");
    }
  }, []);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  // valid email 
  function isValidEmail(email) {
    // Regular expression for a valid email format
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  }

  const handleValidation = () => {
    const { user_name, email, password } = values;
    if (user_name === "") {
      toast.error("Username is required.", toastOptions);
      return false;
    } else if (email === "") {
      toast.error("Email is required.", toastOptions);
      return false;
    } else if (!isValidEmail(email)) {
      toast.error("Please enter a valid email address.", toastOptions);
      return false;
    } else if (password === "") {
      toast.error("Password is required.", toastOptions);
      return false;
    }
    // else if (userChatLogo === "" || userChatLogo === undefined || userChatLogo === null) {
    //   toast.error("Image is required.", toastOptions);
    //   return false;
    // }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingState(true)
    if (handleValidation()) {
      console.log('validated')
      const { user_name, email, password } = values;

      // Upload Image 
      if (image === "" || image === undefined || image === null) {
        console.log("No image ")
        const { data } = await axios.post(registerRoute, {
          user_name,
          email,
          password

        });
         console.log(data)
        if (data.error === true) {
          toast.error(data.message, toastOptions);
    setLoadingState(false)

        }
        if (data.error === false) {
          localStorage.setItem(
            process.env.REACT_APP_LOCALHOST_KEY,
            JSON.stringify(data.data)
          );
          navigate("/");
        }
      } else {
        console.log(" image ")

        var bodyFormData = new FormData();
        bodyFormData.append('image', image);
        axios({
          method: "post",
          url: uploadImageRoute,
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data"
          },
        })
          .then(async function (response) {
            console.log(response)
            if (response.data.path === undefined || response.data.path === null || response.data.path === "") {
              toast.error("Cant Upload Image Right Now", toastOptions);
              setLoadingState(false)

            } else {
              const imageUrl = response.data.path
              // create user 
              const { data } = await axios.post(registerRoute, {
                user_name,
                email,
                password,
                image: imageUrl
              });
              console.log("Data")
              console.log(data)

              if (data.error === true) {
                toast.error(data.message, toastOptions);
    setLoadingState(false)

              }
              if (data.error === false) {
                localStorage.setItem(
                  process.env.REACT_APP_LOCALHOST_KEY,
                  JSON.stringify(data.data)
                );
                navigate("/");
              }

            }
          })
          .catch(function (response) {
            //handle error
            toast.error(response, toastOptions);
    setLoadingState(false)

          });
      }


      // const { data } = await axios.post(registerRoute, {
      //   user_name,
      //   uniq_id,
      //   userChatLogo
      // });

      // if (data.status === false) {
      //   toast.error(data.msg, toastOptions);
      // }
      // if (data.status === true) {
      //   localStorage.setItem(
      //     process.env.REACT_APP_LOCALHOST_KEY,
      //     JSON.stringify(data.user)
      //   );
      //   navigate("/");
      // }
    }else{
    setLoadingState(false)

    }
  };

  return (
    <>
      <FormContainer>
        <form action="" onSubmit={(event) => handleSubmit(event)}>
          <div className="brand">
            <img src={Logo} alt="logo" />
          </div>
          {/* {userChatLogo === "null" || userChatLogo === "undefined" || userChatLogo === "" ? <>
            <div style={{ display: 'flex', justifyContent: 'center' }} >
              <input type="file" accept="image/*" ref={inputRef} style={{ display: 'none' }}
                onChange={(e) => onFileChange1(e.target.files[0])} />
              <img onClick={handleClick}
                src={dummyImage}
                alt="profile"
                style={{ width: "200px", height: "200px", border: '1px solid lightGray', cursor: 'pointer', borderRadius: '50%' }} />
            </div>
          </> :
            <> <div style={{ display: 'flex', justifyContent: 'center' }} >
              <input type="file" accept="image/*" ref={inputRef} style={{ display: 'none' }}
                onChange={(e) => onFileChange1(e.target.files[0])} />
              <img onClick={handleClick} alt="profile"
                src={userChatLogo}
                style={{ width: "200px", height: "200px", border: '1px solid lightGray', cursor: 'pointer', borderRadius: '50%' }} />
            </div>
            </>} */}

            <TextField
          name="user_name"
          onChange={(e) => handleChange(e)}
           sx={{ width: '100%' }} id="outlined-basic" 
           label="Username"
            variant="outlined" />
              <TextField
          name="email"
          onChange={(e) => handleChange(e)}
           sx={{ width: '100%' }} id="outlined-basic" 
           label="Email"
            variant="outlined" />
            <FormControl sx={{  width: '100%' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => handleChange(e)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
          {/* <input
            type="text"
            placeholder="Username"
            name="user_name"
            onChange={(e) => handleChange(e)}
          /> */}
          {/* <input
            type="text"
            placeholder="Email"
            name="email"
            onChange={(e) => handleChange(e)}
          /> */}
          {/* <input
            type="text"
            placeholder="Password"
            name="password"
            onChange={(e) => handleChange(e)}
          /> */}
 <LoadingButton className="buttonClass" loading={loadingState}
                     type="submit">
                   Create Account
                </LoadingButton>
          {/* <button type="submit">Create User</button> */}
          <span>
            Already have an account ? <Link to="/login">Login.</Link>
          </span>
        </form>
      </FormContainer>
      <ToastContainer />
    </>
  );
}

const FormContainer = styled.div`
  height: 100vh;
  overflow:auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #f1efef;
  .brand {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    img {
      height: 5rem;
    }
    h1 {
      color: black;
      text-transform: uppercase;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #ffffff;
    border-radius: 2rem;
    padding: 3rem 5rem;
  }
  input {
    background-color: transparent;
    padding: 1rem;
    border-radius: 0.4rem;
    color: black;
    width: 100%;
    font-size: 1rem;
    &:focus {
      outline: none;
    }
  }
  .buttonClass {
    background-color: #53bdeb;
    color: white;
    padding: 0.5rem 2rem;
    border: none;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0.4rem;
    font-size: 1rem;
    text-transform: uppercase;
    &:hover {
      background-color: #99cde3;
    }
  }
  span {
    color: black;
    text-transform: uppercase;
    a {
      color: #53bdeb;
      text-decoration: none;
      font-weight: bold;
      &:hover{
        color: #99cde3;
    }
    }
  }
`;
